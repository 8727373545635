export function coolgee() {
	// 移动端菜单控制
	$(".menu-btn,.menu-con-bg,.block-menu").click(function () {
		$('.menu-con').toggleClass('open');
		$('.icon-menu-btn').toggleClass('open');
		$('body').toggleClass('open');
		$('.mobile-wrap').toggleClass('open');
		$('.menu-con-bg').fadeToggle();
	});
	// 移动端搜索
	$(".search-btn,.page-mask,.search-close").click(function () {
		$('.search').toggleClass('open');
		$('.page-mask').fadeToggle(300);
	});
	// 移动端侧边栏
	$("#side .block-weixin,#side .wx-ewm .bg").click(function () {
		$('#side .wx-ewm').toggleClass('open');
	});
	// 移动端返回页面顶部
	$(document).ready(function () {
		$(".block-gotop").click(function () {
			$(".mobile-wrap").animate({ scrollTop: 0 }, "slow");
		});
	});
	// 移动端首页二维码
	$(".wx-ewm-btn,.wx-ewm-box .bg").click(function () {
		$('.wx-ewm-box').toggleClass('open');
	});

	// 锚点平滑滚动
	// $('a[href*=#]').click(function() {
	//     if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
	//         var $target = $(this.hash);
	//         $target = $target.length && $target || $('[name=' + this.hash.slice(1) + ']');
	//         if ($target.length) {
	//             var targetOffset = $target.offset().top;
	//             $('html,body').animate({
	//                 scrollTop: targetOffset
	//             },
	//             800);
	//             return false;
	//         }
	//     }
	// });
	$(".block-gotop").click(function () {
		$("html,body").animate({ scrollTop: 0 }, "slow");
	});

	var winHeight = $(document).scrollTop();

	$(window).scroll(function () {
		var scrollY = $(document).scrollTop();

		if (scrollY > 200) {
			$('#side').addClass('on');
		}
		else {
			$('#side').removeClass('on');
		}

		if (scrollY > 210) {
			$('#sider').addClass('on');
			// $('.title-box').addClass('on')
		}
		else {
			$('#sider').removeClass('on');
			// $('.title-box').removeClass('on')
		}
	});
}