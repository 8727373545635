<template>
	<!-- 侧边菜单 -->
	<div id="side" class="open">
		<!-- <a rel="nofllow" class="block-tel pc"><div><span>400 8888 8888</span></div></a> -->
		<a rel="nofllow" class="block-qr pc"><b>移动端浏览</b>
			<div class="pc">
				<img src="../../style/img/weixin1.png"/>
				<span>添加我们微信</span>
			</div>
		</a>
		<a rel="nofllow" class="block-gotop pc" @click="handleGoTop"><b>返回顶部</b></a>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	},
	methods: {
		handleGoTop() {
			var header = document.getElementById('header')
			header.style.top = '0';
			header.style.transition = 'all .4s ease-in-out';

			let top = document.documentElement.scrollTop || document.body.scrollTop
			// 实现滚动效果
			const timeTop = setInterval
			(
				() => 
				{
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50
					if (top <= 0) {
						clearInterval(timeTop)
					}
				}, 
				10
			)

		}
	}
}
</script>