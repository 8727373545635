<template>
	<!-- footer -->
	<div id="footer">
		<div class="bottom">
			<div class="mainBox">
				<div class="cop">版权所有：成都花开万物文化传播有限公司</div>
				<div class="icp">
					<a href="https://beian.miit.gov.cn" target="_blank">蜀ICP备2022009339号-3</a>
					<a target="_blank" href=" " style="margin-left: 15px;" ><img src="../../style/img/beian.png" style="float:left; margin-right: 5px;"/>川公网安备 51015602000308号</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {

		}
	}
}
</script>