<template>
	<div id="wrap">
		<top page="login" :showSearchButton="false" />
		<div class="mobile-wrap">
			<div class="form-box">
				<div class="form-title">用户登录</div>
					<div class="form-group">
						<div class="form-content">
							<input class="form-control" placeholder="手机号" v-model="loginForm.sms.phoneNumbers" />
						</div>
					</div>
					<div class="form-group">
						<div class="form-content">
							<input class="form-control" placeholder="请输入图形验证码" v-model="loginForm.verifyCode" />
							<div @click="refreshCode">
								<identify :identifyCode="identifyCode" />
							</div>
						</div>
					</div>
					<div class="form-group">
						<div class="form-content yzm">
							<input class="form-control" placeholder="请输入短信验证码" v-model="loginForm.sms.verifyCode" />
							<button @click="sendSms" :disabled="sendButtonText !== '获取验证码'">{{sendButtonText}}</button>
						</div>
					</div>
					<div class="form-info">
						*本页面仅限雪花系用户登录，不对外开放
					</div>
					<div class="form-group">
						<button class="btn" @click="handleLogin">登录</button>
					</div>
			</div>
		</div>
		<bottom />
		<side />
	</div>
</template>

<script>
import top from "./components/top.vue";
import bottom from "./components/bottom.vue";
import side from "./components/side.vue";
import identify from './components/identify.vue';
import { mapActions } from "vuex";
export default {
	page: {
		title: '会员登录'
	},
	data() {
		return {
			identifyCodes: '1234567890abcdefjhijklinopqrsduvwxyz',//随机串内容
   			identifyCode: '',
			count: 0,
			sendButtonText: '获取验证码',
			loginForm: {
				verifyCode: '', //图形验证码
				sms: {
					phoneNumbers: '',
					verifyCode: '' //电话验证码
				},
			},

		}
	},
	methods: {
		...mapActions(["auth/login"]),
		// 重置验证码
		refreshCode () {
			this.identifyCode = ''
			this.makeCode(this.identifyCodes, 4)
		},
		makeCode (o, l) {
			for (let i = 0; i < l; i++) {
				this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length)]
			}
		},
		randomNum (min, max) {
			return Math.floor(Math.random() * (max - min) + min)
		},
		sendSms() {
			if(this.loginForm.sms.phoneNumbers === '') {
				this.$message.info('手机号码不能为空')
				return
			}

			if(this.loginForm.verifyCode === '') {
				this.$message.info('图形验证码不能为空')
				return
			}
			else
			{
				if(this.loginForm.verifyCode !== this.identifyCode)
				{
					this.$message.info('图形验证码输入不正确')
					return false
				}
			}

			if(this.sendButtonText !== '获取验证码') {
				return
			}

			this.$api.aliyun.sms.sendSms(this.loginForm.sms.phoneNumbers)
			.then(response => {
				if(response.code === '0') {
					this.count = 60
					this.sendButtonText = `倒计时：${this.count}秒`

					let times = setInterval(() => {
						this.count--
						this.sendButtonText = `倒计时：${this.count}秒`
						if(this.count <= 0) {
							this.sendButtonText = '获取验证码'
							clearInterval(times);
						}
					}, 1000)
				}
				else {
					this.$message.error(response.message)
				}
			})
		},
		handleValidate() {
			if(this.loginForm.sms.phoneNumbers === '') {
				this.$message.info('手机号码不能为空')
				return false
			}
			if(this.loginForm.verifyCode === '') {
				this.$message.info('请输入图形验证码')
				return false
			}
			else
			{
				if(this.loginForm.verifyCode !== this.identifyCode)
				{
					this.$message.info('图形验证码输入不正确')
					return false
				}
			}
			if(this.loginForm.sms.verifyCode === '') {
				this.$message.info('请输入手机验证码')
				return false
			}

			return true
		},
		handleLogin() {
			if(!this.handleValidate()) {
				return
			}

			this["auth/login"](this.loginForm.sms)
			.then(response => {
				if(response.code === '0') {
					if(this.$route.query.redirect) {
						let url = decodeURIComponent(this.$route.query.redirect)
						this.$router.push({ path: url });
					}
					else {
						this.$router.push({path: '/'});
					}
				}
				else {
					this.$message.error(response.message)
				}
			})
		}
	},
	mounted() {
		this.identifyCode = ''
  		this.makeCode(this.identifyCodes, 4)
	},
	components: {
		top,
		bottom,
		side,
		identify
	}
}
</script>

<style src="../style/css/login.css" scoped></style>
<style scoped>

</style>