<template>
	<div>
		<!-- header -->
		<div id="header-block"></div>
		<div id="header">
			<div class="headerBox">
				<div class="logo">
					<a href="/">
						<!-- <img class="icon-logo" src="../../style/img/logo.png"/> -->
					</a>
				</div>
				<div class="con">
					<div class="user-box">
						<a class="login" @click="handleLogin" v-if="!loggedIn">资源库</a>
						<a class="user" @click="handlePersonal" v-if="loggedIn">我的</a>
						<a class="search-btn" @click="handleShowSearch" v-if="showSearchButton">
							<span class="iconfont iconsearch"></span>
						</a>
					</div>
				</div>
				<div class="menu">
					<div class="menu-box">
						<a class="menu-btn" rel="nofllow"><span class="icon-menu-btn"></span></a>
						<div class="menu-con-bg"></div>
						<div class="menu-con">
							<div class="menu-title mobile">MENU</div>
							<ul class="ulnop">
								<li v-for="item in navList" :key="item.title">
									<router-link :to="item.href"><span :style="item.route == page ? 'color: #d9b765' : ''">{{item.title}}</span></router-link>
								</li>
								<li class="mobile">
									<a @click="handleLogin"><span>资源库</span></a>
								</li>
								<li class="mobile" v-if="loggedIn">
									<a @click="handleLogout"><span>退出登录</span></a>
								</li>
							</ul> 
						</div>
					</div>
				</div>
				<!-- menu end -->
			</div>
			
		</div>
		<div class="search-window" style="display: none;">
			<div class="search-bg"></div>
			<div class="search-con">
				<input placeholder="搜索感兴趣的关键词" v-model="search.keywords" />
				<span class="iconfont iconsearch"></span>
				<button @click="handleSearch">搜索</button>
			</div>
		</div>
		<!-- header end -->
	</div>
</template>

<script>
import { coolgee } from "@/scripts/coolgee"
import { mapActions, mapGetters } from "vuex";
export default {
	props: {
		page: {
            type: String,
            required: true,
        },
		showSearchButton: {
			type: Boolean,
            required: true,
		}
	},
	data() {
		return {
			navList: [
				{ title: '流行花艺解析', href: '/', route: 'knowledge' },
				{ title: '图库', href: '/works', route: 'works' },
				{ title: '线下活动', href: '/offline', route: 'offline' },
				{ title: '设计师', href: '/designer', route: 'designer' },
				{ title: '行业招聘', href: '/industryRecruit', route: 'industryRecruit' },
				{ title: '商家推荐', href: '/industry', route: 'industry' },
				{ title: '联系我们', href: '/about', route: 'about' },
			],
			search: {
				keywords: ''
			}
		}
	},
	computed: {
		...mapGetters(["loggedIn"])
	},
	methods: {
		...mapActions(["auth/logout"]),
		handleMouseScroll() {
			var agent = navigator.userAgent;
			var header = document.getElementById('header','navCon')
			if (/.*Firefox.*/.test(agent)) {
				document.addEventListener("DOMMouseScroll", function(e) {
					e = e || window.event;
					var detail = e.detail;
					if (detail > 0) {
						header.style.top = '-250px';
						header.style.transition = 'all 1.2s ease-in-out';
						// navCon.style.top = '-150px';
						// navCon.style.transition = 'all 1.2s ease-in-out';
						// navCon.style.opacity = '0';
					} else {
						header.style.top = '0';
						header.style.transition = 'all .6s ease-in-out';
						// navCon.style.top = '80px';
						// navCon.style.transition = 'all .8s ease-in-out';
						// navCon.style.opacity = '1';
					}
				});
			} 
			else {
				document.onmousewheel = function(e) {
					e = e || window.event;
					var wheelDelta = e.wheelDelta;
					if (wheelDelta > 0) {
						header.style.top = '0';
						header.style.transition = 'all .6s ease-in-out';
						// navCon.style.top = '80px';
						// navCon.style.transition = 'all .8s ease-in-out';
						// navCon.style.opacity = '1';
					} else {
						header.style.top = '-250px';
						header.style.transition = 'all 1.2s ease-in-out';
						// navCon.style.top = '-150px';
						// navCon.style.transition = 'all 1.2s ease-in-out';
						// navCon.style.opacity = '0';
					}
				}
			}
		},
		handleLogin() {
			this.$router.push({ path: '/personal/order' })
		},
		handlePersonal() {
			this.$router.push({ path: '/personal/order' })
		},
		handleLogout() {
			this["auth/logout"]()
			.then(() => {
				location.reload();
			})
		},
		handleShowSearch() {
			this.search.keywords = ''
			$('.search-window').fadeIn("fast");
		},
		handleSearch() {
			this.$emit('search', this.search.keywords)
			$('.search-window').fadeOut("fast");
		}
	},
	mounted() {
		//this.handleMouseScroll()
		coolgee()
	}
}
</script>

<style scoped>

</style>